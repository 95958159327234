import 'core-js';
import 'react-app-polyfill/ie11';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './normalize.css';
import KcAuthenticator from '@itm/kc-authenticator';
import { Loader } from '@itm/loader';
const App = lazy(() => import('./App'));

const AuthGuard = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [kcAuthenticator, setKcAuthenticator] = useState(null);

  useEffect(() => {
    const kcAuthenticator = new KcAuthenticator('/keycloak.json');
    setKcAuthenticator(kcAuthenticator);
    kcAuthenticator.initiateLogin().then(setAuthenticated);
  }, []);

  return authenticated ? (
    <Suspense fallback={<Loader />}>
      <App kcAuthenticator={kcAuthenticator} />
    </Suspense>
  ) : (
    <Loader />
  );
};

const rootElement = document.getElementById('root');
ReactDOM.render(<AuthGuard />, rootElement);
